body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

.pin {
  width: 25px;
  height: 25px;
  border-radius: 50% 50% 50% 0;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}

.pin.pickup {
  background: #4888F4;
}

.pin.dropoff {
  background: #DD4B3E;
}

.pin:after {
  content: "";
  width: 9px;
  height: 9px;
  margin: 8px 0 0 8px;
  background: #fff;
  position: absolute;
  border-radius: 50%;
}

.pulse {
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}

.pulseIcon {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #265AB1;
  border: 2px solid white;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(0, 204, 255, 0.4);
  animation: pulse 3s infinite;
}

.pulseIcon:hover {
    animation: none;
}

@keyframes pulse {
  0% {
      box-shadow: 0 0 0 0 rgba(0, 204, 255, 0.4);
  }

  70% {
      box-shadow: 0 0 0 50px rgba(0, 204, 255, 0);
  }

  100% {
      box-shadow: 0 0 0 0 rgba(0, 204, 255, 0);
  }
}

.ant-table{
  overflow-x: auto;
}
