@keyframes pulseBase {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 204, 255, 0.4);
  }

  70% {
    box-shadow: 0 0 0 50px rgba(0, 204, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 204, 255, 0);
  }
}

@keyframes pulseAssigned {
  0% {
    box-shadow: 0 0 0 0 rgba(54, 174, 113, 0.4);
  }

  70% {
    box-shadow: 0 0 0 50px rgba(54, 174, 113, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(54, 174, 113, 0);
  }
}

.pulseIconBase {
  background: #0082c8;
  animation: pulseBase 3s infinite;
}

.pulseIconAssigned {
  background: #36ae71;
  animation: pulseAssigned 3s infinite;
}